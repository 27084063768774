import React from "react"
import { graphql } from "gatsby"
import JobsLayout from "../components/jobs/jobs-layout"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/style.scss"

export default function JobsTemplate(data) {
    const pageData = data.data.allMarkdownRemark.edges
    return <JobsLayout pageContent={pageData} />
}

export const query = graphql`query{
  allMarkdownRemark (filter: {frontmatter:{type: {eq: "stellenangebot"}}}){
    edges {
      node{
        fields {
          slug
        }
      excerpt
      html
      frontmatter {
        title
        date
        position
        date
      }
    }
  }
}
}`