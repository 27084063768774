import React, { Component } from "react" 
import { Helmet } from "react-helmet"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Header from "../header"
import Sidebar from "../sidebar"
import Img from "gatsby-image"
import JobCard from "./job-card"

import favicon from "../../../src/images/favicon.png"

class JobsLayout extends Component {

    state = {
        sidebarHide: true,
        sidebarClass: 'sidebar',
    }

    handleToggle = () => {
        console.log(this.state.sidebarHide)
        this.setState((prevState) => {
            if(prevState.sidebarHide){
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar sidebar-show-mobile',
                }
            } else {
                return {sidebarHide: !prevState.sidebarHide,
                    sidebarClass: 'sidebar',
                }
            }
            
        })
    }

    render() {
        
        let featuredImage
    
        if(this.props.featuredImage != null) {
            featuredImage = <Img fluid={this.props.featuredImage} />
        }

        return (
            <div className="main" style={{ height: '100%' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Ines Puettcher Pflege</title>
                    <meta name="icon" href={favicon} />
                </Helmet>
                <Header clickFunction={this.handleToggle} menuState={this.state.sidebarHide} /> 
                <div className="wrapper">
                    <Sidebar sidebarClass={this.state.sidebarClass} />
                    <Container fluid className="mainCol">
                        <Row>
                            <Col>
                            <div className="content">
                            <h1>Stellenangebote</h1>
                                {this.props.pageContent.map((entry)=> (
                                    <JobCard className="PostCard" 
                                        jobTitle={entry.node.frontmatter.title}
                                        jobDate={entry.node.frontmatter.date}
                                        linkToJob={entry.node.fields.slug}
                                        jobImage={featuredImage}
                                        jobExcerpt={entry.node.excerpt}
                                    />
                                ))}
                                {featuredImage}
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default JobsLayout