//
//  JOB PREVIEW / CARD
//

import React from "react"

import Card from "react-bootstrap/Card"
import Link from "gatsby-link"

const JobCard = (props) => (
            <div>
                <Card>
                    <div>{props.jobImage}</div>
                    <Card.Body>
                        <Card.Title><h3>{props.jobTitle}</h3></Card.Title>
                        <i className="card-date">
                            {(() => {
                                const dateString = props.jobDate.split("/")
                                const Month = dateString[0]
                                dateString.splice(2,0, Month)
                                dateString.splice(0,1)
                                const dateOut = dateString.join(".")
                                return dateOut
                                })()}
                        </i>
                        <p>{props.jobExcerpt}</p>
                        <Link className="card-link" to={props.linkToJob}>zum Stellenangebot</Link>
                    </Card.Body>
                </Card>
            </div>
        )


export default JobCard